<template>
    <div class="content-body">
        <!-- #region PageHeadingsStart -->
        <div class="row align-items-center justify-content-between mb-0">
            <h3><i data-feather="user" class="nav-icon"></i>
                <span class="titlePage"> Настройки аккаунта </span>

                <div class="btn-group">
                    <button type="button" title="Обновить" class="btn btn-default btn-smx" :disabled="isBusy"
                        @click="Refresh()" v-b-tooltip.hover="{ placement: 'bottom', variant: 'secondary' }"><i
                            class="nav-icon fas fa-sync-alt"></i></button>

                </div>
            </h3>
        </div>
        <!-- #endregion Page Headings End -->
        <div class="row" style="gap: 10px" v-if="!get_error">
            <div class="col-lg-4 col-12 " >
                <div class="card card-layout">

                    <div class="card-body p-0">
                       
                        <div class="d-flex flex-column p-2" >
                            <h3 class="title">Профиль</h3>
                            <div v-if="user!=null" class="avatar align-self-center online  avatar-xxl m-2  p-10">
                                {{ FirstLabel }}
                            </div>
                            <div v-else class=" align-self-center online  avatar-xxl m-2"><b-skeleton type="avatar"  width="90px" height="90px"></b-skeleton></div>
                            <div v-if="user!=null" class="h3 d-flex align-items-center mb-2"> Имя:
                                <span class="fw-normal flex-grow-1 mx-2" v-if="!editName">{{ user.name }}</span>
                                <input v-else class="form-control flex-grow-1 mx-2" :disabled="loading"
                                    v-model="editUser.name">
                                <div class="actions " style="gap:10px">
                                    <button type="button" v-if="!editName" class="btn edit" @click="ShowEditName()"
                                        :disabled="loading" title="Изменить имя">
                                        <i class="fa fa-pencil fa-2x" aria-hidden="true"></i>
                                    </button>
                                    <button type="button" v-if="editName" class="btn add" title="Сохранить"
                                        :disabled="loading" @click="EditName()">
                                        <i class="fa fa-check fa-2x" aria-hidden="true"></i>
                                    </button>
                                    <button type="button" v-if="editName" class="btn delete" title="Отменить"
                                        :disabled="loading" @click="editName = false">
                                        <i class="fa fa-times fa-2x" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <b-skeleton v-else height="40px" class="mb-2"></b-skeleton>
                            <div v-if="user!=null" class="h3 d-flex align-items-center mb-2"> Логин: <span
                                    class="fw-normal flex-grow-1 mx-2" v-if="!editLogin">{{ user.login }}</span>
                                <input v-else class="form-control flex-grow-1 mx-2" :disabled="loading"
                                    v-model="editUser.login">
                                <div class="actions " style="gap:10px">
                                    <button type="button" v-if="!editLogin" class="btn edit" @click="ShowEditLogin()"
                                        :disabled="loading" title="Изменить логин">
                                        <i class="fa fa-pencil fa-2x" aria-hidden="true"></i>
                                    </button>
                                    <button type="button" v-if="editLogin" class="btn add" title="Сохранить"
                                        :disabled="loading" @click="EditLogin()"">
                                        <i class=" fa fa-check fa-2x" aria-hidden="true"></i>
                                    </button>
                                    <button type="button" v-if="editLogin" class="btn delete" title="Отменить"
                                        :disabled="loading" @click="editLogin = false">
                                        <i class="fa fa-times fa-2x" aria-hidden="true"></i>
                                    </button>
                                </div>

                            </div>
                            <b-skeleton v-else height="40px" class="mb-2"></b-skeleton>
                        </div>  
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12">
                <div class="card card-layout">
                    <div class="card-body p-0">
                        <div class="d-flex flex-column p-2">
                            <h3 class="title">Изменение пароля</h3>
                            <form ref="EditPassword" id="EditPassword" class="" @submit.prevent="EditPassword()">
                                <div class="row mb-2">
                                    <div class="col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Текущий пароль</label></div>
                                    <div class="col-12 align-self-center">
                                        <div class="password-container">
                                            <input :type="password.currentPasswordVisible ? 'text' : 'password'"
                                                autocomplete="new-password" autocorrect="off"
                                                placeholder="Текущий пароль" :disabled="loading"
                                                v-model="password.currentPassword" class="form-control" required />
                                            <div class="password-container-eye">
                                                <img v-if="!password.currentPasswordVisible"
                                                    src="@/assets/images/eye/eye_open.png" title="Показать текст пароля"
                                                    @click="password.currentPasswordVisible = true" />
                                                <img v-else src="@/assets/images/eye/eye_hide.png" id="eyeHide"
                                                    @click="password.currentPasswordVisible = false"
                                                    title="Скрыть текст пароля" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Новый пароль</label></div>
                                    <div class="col-12 align-self-center">
                                        <div class="password-container">
                                            <input :type="password.newPasswordVisible ? 'text' : 'password'"
                                                autocomplete="new-password" autocorrect="off"
                                                placeholder="Введите новый пароль" :disabled="loading"
                                                v-model="password.newPassword" class="form-control" required />
                                            <div class="password-container-eye">
                                                <img v-if="!password.newPasswordVisible"
                                                    src="@/assets/images/eye/eye_open.png" title="Показать текст пароля"
                                                    @click="password.newPasswordVisible = true" />
                                                <img v-else src="@/assets/images/eye/eye_hide.png" id="eyeHide"
                                                    @click="password.newPasswordVisible = false"
                                                    title="Скрыть текст пароля" />
                                            </div>
                                        </div>
                                        <div class="invalid-feedback d-block" v-if="ErrorNewPassword">Новый пароль
                                            совпадает с ведённым старым</div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-12 align-self-center"><label
                                            class="form-label font-weight-bold">Подтверждение пароля</label></div>
                                    <div class="col-12 align-self-center">
                                        <div class="password-container">
                                            <input :type="password.newRepeatPasswordVisible ? 'text' : 'password'"
                                                autocomplete="new-password" autocorrect="off"
                                                placeholder="Повторите новый пароль" :disabled="loading"
                                                v-model="password.newRepeatPassword" class="form-control" required />
                                            <div class="password-container-eye">
                                                <img v-if="!password.newRepeatPasswordVisible"
                                                    src="@/assets/images/eye/eye_open.png" title="Показать текст пароля"
                                                    @click="password.newRepeatPasswordVisible = true" />
                                                <img v-else src="@/assets/images/eye/eye_hide.png" id="eyeHide"
                                                    @click="password.newRepeatPasswordVisible = false"
                                                    title="Скрыть текст пароля" />
                                            </div>
                                        </div>
                                        <div class="invalid-feedback d-block" v-if="ErrorNewRepeatPassword">Повторный
                                            ввод нового пароля не совпадает с новым паролем</div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center flex-wrap" style="gap:0.5rem">
                                    <button :disabled="PasswordError || loading" type="submit"
                                        class="btn btn-primary text-center btn-sm align-items-center  flex-grow-1 flex-md-grow-0">
                                        <span v-if="!loading">Сохранить изменения</span>
                                        <span v-else>
                                            <span class="spinner-border spinner-border-sm mr-1" role="status"
                                                aria-hidden="true"></span>
                                            <span>&nbsp; Загрузка...</span>
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="text-center my-2" style="color:red"> Ошибка загрузки </div>
        </div>

        <bs-modal ref="EmptyModal" id="EmptyModalProfile" centered></bs-modal>

    </div>
</template>
<script>import { CHANGE_LOGIN_PUT_REQUEST, CHANGE_NAME_PUT_REQUEST, CHANGE_PASSWORD_PUT_REQUEST, USERS_BY_GUID_GET_REQUEST, USERS_CURRENT_GET_REQUEST } from '@/helpers/actions';
import indDB from '@/helpers/IndexedDB'

export default {
    data() {
        return {
            isBusy: false,
            editLogin: false,
            editName: false,
            editUser: {
                name: null,
                login: null,
            },
            user: null,
            password: {
                newPassword: null,
                newPasswordVisible: false,
                currentPassword: null,
                currentPasswordVisible: false,
                newRepeatPassword: null,
                newRepeatPasswordVisible: false,
            },
            loading: false,
            get_error: false,
        }
    },
    computed: {

        FirstLabel() {
            return this.user?.name.substring(0, 2)
        },
        ErrorNewPassword() {
            return (this.password.newPassword != null && this.password.newPassword != "" && this.password.newPassword == this.password.currentPassword);
        },
        ErrorNewRepeatPassword() {
            if (this.password.newPassword != null || this.password.newPassword != "")
                return this.password.newPassword !== this.password.newRepeatPassword;
            else {
                return false;
            }
        },
        PasswordError() {
            return (this.password.newPassword == "" || this.password.newPassword == null)
                || (this.password.newRepeatPassword == "" || this.password.newRepeatPassword == null)
                || (this.password.currentPassword == "" || this.password.currentPassword == null)
                || this.ErrorNewRepeatPassword
                || this.ErrorNewPassword
        }
    },
    methods: {
        ShowEditLogin() { this.editUser.login = this.user.login; this.editLogin = true },
        async EditLogin() {
            this.loading = true;
            await this.$store.dispatch(CHANGE_LOGIN_PUT_REQUEST, this.editUser.login).then(resp => {
                this.user = Object.assign({}, resp.data)
                this.editLogin = false;
                this.$refs.EmptyModal.success()
            }).catch(err => this.$refs.EmptyModal.error(err)
            ).catch(err => this.$refs.EmptyModal.error(err))
            this.loading = false;


        },
        ShowEditName() { this.editUser.name = this.user.name; this.editName = true },
        async EditName() {
            this.loading = true;
            await this.$store.dispatch(CHANGE_NAME_PUT_REQUEST, this.editUser.name).then(resp => {
                this.user = Object.assign({}, resp.data)
                this.editName = false;
                this.$refs.EmptyModal.success()
            }).catch(err => this.$refs.EmptyModal.error(err))
            this.loading = false;

        },
        async EditPassword() {
            this.loading = true;
            await this.$store.dispatch(CHANGE_PASSWORD_PUT_REQUEST, { currentPassword: this.password.currentPassword, newPassword: this.password.newPassword }).then(resp => {
                this.password.currentPassword = null;
                this.password.newPassword = null;
                this.password.newRepeatPassword = null;
                this.$refs.EmptyModal.success()
            }
            ).catch(err => this.$refs.EmptyModal.error(err))
            this.loading = false;
        },

        async Refresh() {
            await this.GetData();
        },

        async GetData() {
            this.isBusy = true;
            let user_id;

            await indDB.getUserToken().then(
                async el => {

                    if (el.role == "admin") {

                        await indDB.getMagicToken().then(el1 => user_id = el1.userName)
                    }
                    else
                        user_id = el.userName
                });
            await this.$store.dispatch(USERS_BY_GUID_GET_REQUEST, user_id).then(resp => {
                if (resp.data != null && resp.data.length > 0) {

                    this.get_error = false;
                    this.user = Object.assign({}, resp.data[0])
                }
                else this.get_error = true;

            }).catch(
                err => {

                    this.get_error = true;
                }
            )
            this.isBusy = false;

        }
    },

    async created() {
        await this.GetData();
    },

    async mounted() {
        feather.replace();

    },
}
</script>
<style scoped>
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
    display: none;
}

.password-container {
    position: relative;
}

.password-container-eye {
    height: 20px;
    position: absolute;
    width: 20px;
    left: calc(100% - 30px);
    top: calc(50% - 10px);
}

.password-container-eye>img {
    position: relative;

}
</style>