export const CHECK_HTTP_ERROR = "CHECK_HTTP_ERROR";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_GET_MAGIC_TOKEN_REQUEST = "AUTH_GET_MAGIC_TOKEN_REQUEST";

//USERS
export const USERS_GET_REQUEST = "USERS_GET_REQUEST";
export const USERS_GET_SUCCESS = "USERS_GET_SUCCES";
export const USERS_GET_ERROR = "USERS_GET_ERROR";


export const USERS_BY_GUID_GET_REQUEST = "USERS_BY_GUID_GET_REQUEST";
export const USERS_CURRENT_GET_REQUEST = "USERS_CURRENT_GET_REQUEST";
export const USERS_CURRENT_GET_SUCCESS= "USERS_CURRENT_GET_SUCCESS";

export const USERS_POST_REQUEST = "USERS_POST_REQUEST";
export const USERS_POST_SUCCESS = "USERS_POST_SUCCESS";
export const USERS_POST_ERROR = "USERS_POST_ERROR";

export const USERS_PUT_REQUEST = "USERS_PUT_REQUEST";
export const USERS_PUT_SUCCESS = "USERS_PUT_SUCCESS";
export const USERS_PUT_ERROR = "USERS_PUT_ERROR";

export const USERS_DELETE_REQUEST = "USERS_DELETE_REQUEST"
export const USERS_DELETE_SUCCESS = "USERS_DELETE_SUCCESS"
export const USERS_DELETE_ERROR = "USERS_DELETE_ERROR"
export const CHANGE_NAME_PUT_REQUEST = "CHANGE_NAME_PUT_REQUEST"
export const CHANGE_LOGIN_PUT_REQUEST = "CHANGE_LOGIN_PUT_REQUEST"
export const CHANGE_PASSWORD_PUT_REQUEST = "CHANGE_PASSWORD_PUT_REQUEST"
//PRODUCTS
export const PRODUCTS_GET_REQUEST = "PRODUCTS_GET_REQUEST";
export const ADMIN_PRODUCTS_GET_REQUEST = "ADMIN_PRODUCTS_GET_REQUEST"

export const PRODUCTS_GET_SUCCESS = "PRODUCTS_GET_SUCCESS";
export const PRODUCTS_GET_ERROR = "PRODUCTS_GET_ERROR";

export const PRODUCTS_POST_REQUEST = "PRODUCTS_POST_REQUEST";
export const ADMIN_PRODUCTS_POST_REQUEST = "ADMIN_PRODUCTS_POST_REQUEST"
export const PRODUCTS_POST_SUCCESS = "PRODUCTS_POST_SUCCESS";
export const PRODUCTS_POST_ERROR = "PRODUCTS_POST_ERROR";

export const PRODUCTS_PUT_REQUEST = "PRODUCTS_PUT_REQUEST";
export const PRODUCTS_PUT_SUCCESS = "PRODUCTS_PUT_SUCCESS";
export const PRODUCTS_PUT_ERROR = "PRODUCTS_PUT_ERROR";

export const PRODUCTS_DELETE_REQUEST = "PRODUCTS_DELETE_REQUEST"
export const PRODUCTS_DELETE_SUCCESS = "PRODUCTS_DELETE_SUCCESS"
export const PRODUCTS_DELETE_ERROR = "PRODUCTS_DELETE_ERROR"



//ORGANIZATIONS
export const ORGANIZATIONS_GET_REQUEST = "ORGANIZATIONS_GET_REQUEST";
export const ADMIN_ORGANIZATIONS_GET_REQUEST = "ADMIN_ORGANIZATIONS_GET_REQUEST"

export const ORGANIZATIONS_GET_SUCCESS = "ORGANIZATIONS_GET_SUCCESS";
export const ORGANIZATIONS_GET_ERROR = "ORGANIZATIONS_GET_ERROR";

export const ORGANIZATIONS_POST_REQUEST = "ORGANIZATIONS_POST_REQUEST";
export const ADMIN_ORGANIZATIONS_POST_REQUEST = "ADMIN_ORGANIZATIONS_POST_REQUEST"
export const ORGANIZATIONS_POST_SUCCESS = "ORGANIZATIONS_POST_SUCCESS";
export const ORGANIZATIONS_POST_ERROR = "ORGANIZATIONS_POST_ERROR";

export const ORGANIZATIONS_PUT_REQUEST = "ORGANIZATIONS_PUT_REQUEST";
export const ORGANIZATIONS_PUT_SUCCESS = "ORGANIZATIONS_PUT_SUCCESS";
export const ORGANIZATIONS_PUT_ERROR = "ORGANIZATIONS_PUT_ERROR";

export const ORGANIZATIONS_DELETE_REQUEST = "ORGANIZATIONS_DELETE_REQUEST"
export const ORGANIZATIONS_DELETE_SUCCESS = "ORGANIZATIONS_DELETE_SUCCESS"
export const ORGANIZATIONS_DELETE_ERROR = "ORGANIZATIONS_DELETE_ERROR"

///ISSUEDCARDS
export const ISSUEDCARDS_GET_REQUEST = "ISSUEDCARDS_GET_REQUEST";
export const ISSUEDCARDS_GET_ERROR = "ISSUEDCARDS_GET_ERROR";
export const ISSUEDCARDS_GET_SUCCESS = "ISSUEDCARDS_GET_SUCCESS";

export const ISSUEDCARDS_FUEL_POST_REQUEST = "ISSUEDCARDS_FUEL_POST_REQUEST";
export const ISSUEDCARDS_DRIVER_POST_REQUEST = "ISSUEDCARDS_DRIVER_POST_REQUEST";
export const ISSUEDCARDS_POST_REQUEST = "ISSUEDCARDS_POST_REQUEST";
export const ISSUEDCARDS_POST_ERROR = "ISSUEDCARDS_POST_ERROR";
export const ISSUEDCARDS_POST_SUCCESS = "ISSUEDCARDS_POST_SUCCESS";

///DRIVERS
export const DRIVERS_GET_REQUEST = "DRIVERS_GET_REQUEST";
export const DRIVERS_GET_BY_GUID_REQUEST = "DRIVERS_GET_BY_GUID_REQUEST";
export const DRIVERS_GET_BY_GUID_SUCCESS = "DRIVERS_GET_BY_GUID_SUCCESS";

export const ADMIN_DRIVERS_GET_REQUEST = "ADMIN_DRIVERS_GET_REQUEST"

export const DRIVERS_GET_SUCCESS = "DRIVERS_GET_SUCCESS";
export const DRIVERS_GET_ERROR = "DRIVERS_GET_ERROR";

export const DRIVERS_POST_REQUEST = "DRIVERS_POST_REQUEST";
export const ADMIN_DRIVERS_POST_REQUEST = "ADMIN_DRIVERS_POST_REQUEST"
export const DRIVERS_POST_SUCCESS = "DRIVERS_POST_SUCCESS";
export const DRIVERS_POST_ERROR = "DRIVERS_POST_ERROR";

export const DRIVERS_PUT_REQUEST = "DRIVERS_PUT_REQUEST";
export const DRIVERS_PUT_SUCCESS = "DRIVERS_PUT_SUCCESS";
export const DRIVERS_PUT_ERROR = "DRIVERS_PUT_ERROR";

export const DRIVERS_DELETE_REQUEST = "DRIVERS_DELETE_REQUEST"
export const DRIVERS_DELETE_SUCCESS = "DRIVERS_DELETE_SUCCESS"
export const DRIVERS_DELETE_ERROR = "DRIVERS_DELETE_ERROR"




//COMMAND
export const COMMANDCANCEL_DELETE_REQUEST = "COMMANDCANCEL_DELETE_REQUEST ";
export const COMMANDCANCEL_DELETE_SUCCESS = "COMMANDCANCEL_DELETE_SUCCESS"
//CARDS
export const CARDS_GET_REQUEST = "CARDS_GET_REQUEST";
export const CARDS_GET_SUCCESS = "CARDS_GET_SUCCESS";
export const CARDS_GET_ERROR = "CARDS_GET_ERROR";
export const CARDS_BLOCK_PUT_REQUEST = "CARDS_BLOCK_PUT_REQUEST";
export const CARDS_UNBLOCK_PUT_REQUEST = "CARDS_UNBLOCK_PUT_REQUEST";
export const CARDS_REISSUE_PUT_REQUEST = "CARDS_REISSUE_PUT_REQUEST"
export const CARDS_GET_BY_GUID_REQUEST = "CARDS_GET_BY_GUID_REQUEST";
export const CARDS_GET_BY_GUID_SUCCESS = "CARDS_GET_BY_GUID_SUCCESS";

export const CARDS_BOT_GET_REQUEST = "CARDS_BOT_GET_REQUEST";


export const CARDS_POST_REQUEST = "CARDS_POST_REQUEST";
export const CARDS_POST_SUCCESS = "CARDS_POST_SUCCESS";
export const CARDS_POST_ERROR = "CARDS_POST_ERROR";

export const CARDS_PUT_REQUEST = "CARDS_PUT_REQUEST";
export const CARDS_PUT_SUCCESS = "CARDS_PUT_SUCCESS";
export const CARDS_PUT_ERROR = "CARDS_PUT_ERROR";

export const CARDS_DELETE_REQUEST = "CARDS_DELETE_REQUEST"
export const CARDS_DELETE_SUCCESS = "CARDS_DELETE_SUCCESS"
export const CARDS_DELETE_ERROR = "CARDS_DELETE_ERROR"

//WALLETS
export const WALLETS_GET_REQUEST = "WALLETS_GET_REQUEST";

export const WALLETS_GET_SUCCESS = "WALLETS_GET_SUCCESS";
export const WALLETS_GET_ERROR = "WALLETS_GET_ERROR";
export const WALLETS_GET_BY_GUID_REQUEST = "WALLETS_GET_BY_GUID_REQUEST";
export const WALLETS_GET_BY_GUID_SUCCESS = "WALLETS_GET_BY_GUID_SUCCESS";

export const WALLETS_POST_REQUEST = "WALLETS_POST_REQUEST";
export const WALLETS_POST_SUCCESS = "WALLETS_POST_SUCCESS";
export const WALLETS_POST_ERROR = "WALLETS_POST_ERROR";

export const WALLETS_PUT_REQUEST = "WALLETS_PUT_REQUEST";
export const WALLETS_GET_PAYMENT_REQUEST = "WALLETS_GET_PAYMENT_REQUEST"
export const WALLETS_PUT_SUCCESS = "WALLETS_PUT_SUCCESS";
export const WALLETS_PUT_ERROR = "WALLETS_PUT_ERROR";

export const WALLETS_DELETE_REQUEST = "WALLETS_DELETE_REQUEST"
export const WALLETS_DELETE_SUCCESS = "WALLETS_DELETE_SUCCESS"
export const WALLETS_DELETE_ERROR = "WALLETS_DELETE_ERROR"
export const WALLETS_DELETE_SUCCESS_TRUE = "WALLETS_DELETE_SUCCESS_TRUE"

//TRANSACTIONS
export const TRANSACTIONS_GET_BY_DATETIME_REQUEST = "TRANSACTIONS_GET_BY_DATETIME_REQUEST";
export const TRANSACTIONS_GET_BY_ID_REQUEST = "TRANSACTIONS_GET_BY_ID_REQUEST";



//TERMINALS


export const TERMINALS_UPDATE_CONF_POST_REQUEST = "TERMINALS_UPDATE_CONF_POST_REQUEST"

export const TERMINALS_GET_REQUEST = "TERMINALS_GET_REQUEST";
export const TERMINALS_GET_SUCCESS = "TERMINALS_GET_SUCCESS";
export const TERMINALS_GET_ERROR = "TERMINALS_GET_ERROR";

export const TANK_HISTORY_GET_REQUEST = "TANK_HISTORY_GET_REQUEST"
export const TRASFER_TERMINAL_PUT_REQUEST = "TRASFER_TERMINAL_PUT_REQUEST";

export const TERMINALS_GET_BY_GUID_REQUEST = "TERMINALS_GET_BY_GUID_REQUEST";
export const TERMINALS_GET_BY_GUID_SUCCESS = "TERMINALS_GET_BY_GUID_SUCCESS";

export const TERMINALS_POST_REQUEST = "TERMINALS_POST_REQUEST";
export const TERMINALS_POST_SUCCESS = "TERMINALS_POST_SUCCESS";
export const TERMINALS_POST_ERROR = "TERMINALS_POST_ERROR";

export const TERMINALS_PUT_REQUEST = "TERMINALS_PUT_REQUEST";
export const TERMINALS_PUT_SUCCESS = "TERMINALS_PUT_SUCCESS";
export const TERMINALS_PUT_ERROR = "TERMINALS_PUT_ERROR";

export const TERMINALS_DELETE_REQUEST = "TERMINALS_DELETE_REQUEST"
export const TERMINALS_DELETE_SUCCESS = "TERMINALS_DELETE_SUCCESS"
export const TERMINALS_DELETE_ERROR = "TERMINALS_DELETE_ERROR"

//ADMIN_CLIENTS


export const ADMIN_CLIENTS_GET_REQUEST = "ADMIN_CLIENTS_GET_REQUEST";
export const ADMIN_CLIENTS_GET_SUCCESS = "ADMIN_CLIENTS_GET_SUCCESS";
export const ADMIN_CLIENTS_GET_ERROR = "ADMIN_CLIENTS_GET_ERROR";


export const ADMIN_CLIENTS_GET_BY_GUID_REQUEST = "ADMIN_CLIENTS_GET_BY_GUID_REQUEST";
export const ADMIN_CLIENTS_GET_BY_GUID_SUCCESS = "ADMIN_CLIENTS_GET_BY_GUID_SUCCESS";

export const ADMIN_CLIENTS_POST_REQUEST = "ADMIN_CLIENTS_POST_REQUEST";
export const ADMIN_CLIENTS_POST_SUCCESS = "ADMIN_CLIENTS_POST_SUCCESS";
export const ADMIN_CLIENTS_POST_ERROR = "ADMIN_CLIENTS_POST_ERROR";

export const ADMIN_CLIENTS_PUT_REQUEST = "ADMIN_CLIENTS_PUT_REQUEST";
export const ADMIN_CLIENTS_PUT_SUCCESS = "ADMIN_CLIENTS_PUT_SUCCESS";
export const ADMIN_CLIENTS_PUT_ERROR = "ADMIN_CLIENTS_PUT_ERROR";

export const ADMIN_CLIENTS_DELETE_REQUEST = "ADMIN_CLIENTS_DELETE_REQUEST"
export const ADMIN_CLIENTS_DELETE_SUCCESS = "ADMIN_CLIENTS_DELETE_SUCCESS"
export const ADMIN_CLIENTS_DELETE_ERROR = "ADMIN_CLIENTS_DELETE_ERROR"


//ADMIN_CARDS

export const ADMIN_CARDS_GET_REQUEST = "ADMIN_CARDS_GET_REQUEST";
export const ADMIN_CARDS_GET_SUCCESS = "ADMIN_CARDS_GET_SUCCESS";
export const ADMIN_CARDS_GET_ERROR = "ADMIN_CARDS_GET_ERROR";


export const ADMIN_CARDS_GET_BY_GUID_REQUEST = "ADMIN_CARDS_GET_BY_GUID_REQUEST";
export const ADMIN_CARDS_GET_BY_GUID_SUCCESS = "ADMIN_CARDS_GET_BY_GUID_SUCCESS";

export const ADMIN_CARDS_POST_REQUEST = "ADMIN_CARDS_POST_REQUEST";
export const ADMIN_CARDS_POST_SUCCESS = "ADMIN_CARDS_POST_SUCCESS";
export const ADMIN_CARDS_POST_ERROR = "ADMIN_CARDS_POST_ERROR";

export const ADMIN_CARDS_PUT_REQUEST = "ADMIN_CARDS_PUT_REQUEST";
export const ADMIN_CARDS_PUT_SUCCESS = "ADMIN_CARDS_PUT_SUCCESS";
export const ADMIN_CARDS_PUT_ERROR = "ADMIN_CARDS_PUT_ERROR";

export const ADMIN_CARDS_DELETE_REQUEST = "ADMIN_CARDS_DELETE_REQUEST"
export const ADMIN_CARDS_DELETE_SUCCESS = "ADMIN_CARDS_DELETE_SUCCESS"
export const ADMIN_CARDS_DELETE_ERROR = "ADMIN_CARDS_DELETE_ERROR"




export const SET_HTTP_401_STATUS = "SET_HTTP_401_STATUS";
export const CLEAR_FLAGS = "CLEAR_FLAGS";
