import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login/Login.vue'
import Cards from '../views/Cards/Cards.vue'
import Terminals_map from '../views/Terminals/Terminals_map.vue'
import Terminals from '../views/Terminals/Terminals.vue'
import Directory from '../views/Directory/DirectoryView.vue'
import Departments from '../views/Directory/Views/DepartmentsView.vue'
import Drivers from '../views/Directory/Views/DriversView.vue'
import Products from '../views/Directory/Views/ProductsView.vue'
import IssuedCards from '../views/Cards/IssuedCards.vue'
import Transactions from '../views/Transactions/Transactions.vue'
import Admin from '../views/Admin/Admin.vue'
import AdminClients from '../views/Admin/AdminClients/AdminClients.vue'
import AdminCards from '../views/Admin/AdminCards/AdminCards.vue'
import Notfound from '../views/404.vue'

import store from '@/store'
import indDB from '@/helpers/IndexedDB'
 
import Profile from '@/views/Profile.vue'
Vue.use(VueRouter)
const routes =
    /*{
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () {
        return import(/* webpackChunkName: "about" / '../views/AboutView.vue')
      }
    }*/
    [
        { path: '/', name: 'Base' },
        {
            path: '/autorization/login', name: 'Login', component: Login,
        },
        {
            path: '/admin', name: 'Admin', component: Admin, redirect: { name: 'AdminClients' },
            children:
                [
                    { path: 'clients', name: 'AdminClients', component: AdminClients },
                    { path: 'cards', name: 'AdminCards', component: AdminCards }
                ]
        },
        {
            path: '/home', name: 'Index', component: Home, redirect: { name: 'Cards' },
            children:
                [
                    {
                        path: 'terminals_map', name: 'Terminals_map', component: Terminals_map,
                    },
                    {
                        path: 'vehicles', name: 'Cards', component: Cards,
                    },
                    {
                        path: 'issuedCards', name: 'IssuedCards', component: IssuedCards,
                    },
                    {
                        path: 'terminals', name: 'Terminals', component: Terminals,
                    },
                    {
                        path: 'directory', name: 'Directory', component: Directory, redirect: { name: 'Departments' }, children:
                            [
                                {
                                    path: 'departments', name: 'Departments', component: Departments,
                                },
                                {
                                    path: 'products', name: 'Products', component: Products,
                                },
                                {
                                    path: 'drivers', name: 'Drivers', component: Drivers,
                                },
                            ]
                    },
                    
                    {
                        path: 'transactions', name: 'Transactions', component: Transactions,
                    },
                    {path: 'profile', name: 'Profile', component: Profile}
                ]
        },
        {
            path: '*', component: Notfound
        },
    ]
const router = new VueRouter({
    mode: "history",
    routes
})
Vue.prototype.$move = function (params) {
    // get comparable fullPaths
    let from = this.$route.fullPath
    let to = this.$router.resolve(params).route.fullPath
    if (from === to) {
        // this.$router.push(this.$route.path)
        // handle any error due the redundant navigation here
        // or handle any other param modification and route afterwards
        return
    }
    // route as expected
    this.$router.push(params)
}
router.afterEach(async (to, from) => {
    if (from.fullPath.indexOf("admin") == 1 && to.fullPath.indexOf("home") == 1) location.reload();
    if (from.fullPath.indexOf("home") == 1 && to.fullPath.indexOf("admin") == 1) location.reload();

})
router.beforeEach(async (to, from, next) => {

    //Vue.use(VeeValidate); // good to go.
    if (await isAuthenticated()) {
        if (to.name == "Base") {
            if (hasPermissionsNeed() == "admin") { await next({ path: '/admin' }) }
            else await next({ path: '/home' })
        }
        if (to.name == "Login") {

            if (hasPermissionsNeed() == "admin") { await next({ path: '/admin' }) }
            else await next({ path: '/home' })
        }

        if (to.fullPath.indexOf("admin") == 1 && await hasPermissionsNeed() != "admin") {

            store.dispatch(AUTH_LOGOUT)
        } else await next();

        if (to.fullPath.indexOf("home") == 1 && (await hasPermissionsNeed() == "user" || await hasMagicToken())) {

            await next()
        }
        else {

            if (await hasPermissionsNeed() == "admin") {
                if (to.fullPath.indexOf("admin") == 1) {
                    await next();
                }
                else
                    await next({ path: '/admin' })
            }

        };

        await next();

        //  else if (to.name == "Login" && await hasPermissionsNeed() == "admin") { await next({ path: '/admin/clients' }) }
        // else if (to.path == "/admin" && await hasPermissionsNeed() != "admin") { await next({ path: '/admin' }) }
        //  else if (to.fullPath.indexOf("admin") == -1 && await hasPermissionsNeed() == "admin") { await next({ path: '/admin/clients' }) }
    } else {

        if (to.name == "Base") {
            await next({ name: 'Login' });
        }
        else
            if (to.name == "Login") {
                await next()
            }
            else await next()

    }
})
/*
router.beforeEach(async (to, from, next) => {
  if (await isAuthenticated()) {
      if (to.name == "Login" && await hasPermissionsNeed(to)=="user") { await next({ path: '/' }) }
      // if (await hasPermissionsNeed(to) == false) {  }
      else next()
  } else {
      if (to.name == "Login"  || to.name=="SuperLogin") {
          await next();
      }
      else {
          await next({ name: 'Login' });
      }
  }
})
*/
async function hasMagicToken() {

    var flag = false;
    if (await indDB.getMagicToken()) return true
    else return false;
}
async function isAuthenticated() {
    let flag = false;
    // if (sessionStorage.getItem('jwt') !== null) return true
    await indDB.getUserToken().then((elem) => { if (elem) { flag = true }; })  // if(element!=null)  return true;})
    return flag
    // return false
    //.catch(err=>{return false;})
}
async function hasPermissionsNeed() {
    var role;
    await indDB.getUserToken().then(elem => role = elem?.role);
    //    if (param.path.split('/')[2] != role || param.path.split('/').length < 3) { return false; }
    // else true;
    return role
}
export default router
