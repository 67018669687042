
const DB_NAME = 'CardServer'
const STORAGES_NAMES = ['tokens', 'magic_tokens']
const DB_VERSION = 1
let DB
export default {
  async getDb() {
    return new Promise((resolve, reject) => {
      if (DB) {
        resolve(DB)
      }
      const request = window.indexedDB.open(DB_NAME, DB_VERSION)
      request.onupgradeneeded = event => {
        let db = event.target.result;
        if (!db.objectStoreNames.contains(STORAGES_NAMES[0]))
          db.createObjectStore(STORAGES_NAMES[0], { keyPath: 'jwt' });
        if (!db.objectStoreNames.contains(STORAGES_NAMES[1]))
          db.createObjectStore(STORAGES_NAMES[1], { keyPath: 'jwt' });
      }
      request.onsuccess = (event) => {
        DB = event.target.result;
        resolve(DB);
      }
      request.onerror = (event) => {
        console.log('Error opening db', e)
        reject('Ошибка открытия базы данных' + event.target.errorCode)
      }
    })
  },
  async PartialUpdate(jwt, name) {
    const db = await this.getDb();
    return new Promise((resolve, reject) => {
      let tx = db.transaction([STORAGES_NAMES[0]], 'readwrite');
      let store = tx.objectStore(STORAGES_NAMES[0]);
      // Ищем запись по ключу (например, jwt)
      let request = store.get(jwt);

      request.onsuccess = event => {

        let record = event.target.result;

        if (record) {
          record.name = name;
          // Обновляем только указанные поля

          let updateRequest = store.put(record);

          updateRequest.onsuccess = () => resolve();
          updateRequest.onerror = (updateEvent) => {
            console.error("Error updating fields:", updateEvent.target.errorCode);
            reject(updateEvent.target.errorCode);
          }
        }
        else {resolve();};
      };
      request.onerror = event => {
        console.error("Error fetching record:", event.target.errorCode);
        reject(event.target.errorCode);
      };
    });
  },
  async addToUserTokens(jwt, userName, tokenID, role, name) {
    let db = await this.getDb()
    return new Promise((resolve, reject) => {
      let tx = db.transaction([STORAGES_NAMES[0]], 'readwrite');
      let store = tx.objectStore(STORAGES_NAMES[0]);
      store.add({ jwt, userName, tokenID, role, name })
      tx.oncomplete = () => {
        resolve();
      }
      tx.onerror = (event) => {
        reject(event.target.errorCode)
      }
    })
  },
  async getUserToken() {
    const db = await this.getDb();
    return new Promise((resolve, reject) => {
      let tx = db.transaction([STORAGES_NAMES[0]], 'readonly');
      tx.oncomplete = () => {
        resolve(note)
      }
      let store = tx.objectStore(STORAGES_NAMES[0]);
      var note = null;
      let request = store.openCursor();
      request.onsuccess = event => {
        // Результатом req.onsuccess в запросах openCursor является
        // IDBCursor
        const cursor = event.target.result;
        if (cursor != null) {
          // Если курсор не нулевой, мы получили элемент.
          note = cursor.value;
          cursor.continue();
        }
      }
      request.onerror = event => {
        console.log(event.target.errorCode);
        reject(event.target.errorCode)
      }
    })
  },
  async deleteUserToken() {
    const token = await this.getUserToken();
    if (token != null) {
      const db = await this.getDb();
      return new Promise((resolve, reject) => {
        let tx = db.transaction([STORAGES_NAMES[0]], 'readwrite');
        let store = tx.objectStore(STORAGES_NAMES[0])
        store.delete(token.jwt);
        tx.oncomplete = () => {
          resolve()
        };
        tx.onerror = (event) => {
          reject(event.target.errorCode)
        }
      })
    }
  },
  async addToMagicTokens(jwt, userName, tokenID, role, name) {
    let db = await this.getDb()
    return new Promise((resolve, reject) => {
      let tx = db.transaction([STORAGES_NAMES[1]], 'readwrite');
      let store = tx.objectStore(STORAGES_NAMES[1]);
      store.add({ jwt, userName, tokenID, role, name })
      tx.oncomplete = () => {
        resolve();
      }
      tx.onerror = (event) => {
        reject(event.target.errorCode)
      }
    })
  },
  async getMagicToken() {
    const db = await this.getDb();
    return new Promise((resolve, reject) => {
      let tx = db.transaction([STORAGES_NAMES[1]], 'readonly');
      tx.oncomplete = () => {
        resolve(note)
      }
      let store = tx.objectStore(STORAGES_NAMES[1]);
      var note = null;
      let request = store.openCursor();
      request.onsuccess = event => {
        // Результатом req.onsuccess в запросах openCursor является
        // IDBCursor
        const cursor = event.target.result;
        if (cursor != null) {
          // Если курсор не нулевой, мы получили элемент.
          note = cursor.value;
          cursor.continue();
        }
      }
      request.onerror = event => {
        console.log(event.target.errorCode);
      }
    })
  },
  async deleteMagicToken() {
    const token = await this.getMagicToken();
    if (token != null) {
      const db = await this.getDb();
      return new Promise((resolve, reject) => {
        let tx = db.transaction([STORAGES_NAMES[1]], 'readwrite');
        let store = tx.objectStore(STORAGES_NAMES[1])
        store.delete(token.jwt);
        tx.oncomplete = () => {
          resolve()
        };
        tx.onerror = (event) => {
          reject(event.target.errorCode)
        }
      })
    }
  },
}
