<template>
    <div class="main-wrapper">
        <div class="authentication-wrapper row gx-0">
            <div class="col-12 mb-6 position-absolute">
                <b-alert :show="dismissCountDown" dismissible @dismissed="dismissCountDown = 0"
                    @dismiss-count-down="countDownChanged" variant="danger">
                    <p> <strong>{{ HttpStatus401Message }}</strong></p>
                    <b-progress variant="danger" :max="dismissSecs" :value="dismissCountDown" height="4px"></b-progress>
                </b-alert>
            </div>

            <!-- Login Form Start -->
            <div class="col-12 align-self-center my-10 px-3">
                <div class="login-rtegister-form login-form">
                    <span class="logo-default text-start position-absolute">
                        <img src="@/assets/images/logo/logo_2.png" id="AMS_logo" style="max-width: 80px;"
                            alt="Logo Default" />
                    </span>
                    <div class="head">
                        <h3 class="title">Авторизация</h3>
                        <hr>
                    </div>
                    <form @submit.prevent="Account()">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <label class="form-label fw-600" for="username">Логин</label>
                                <input type="text" id="username" class="form-control form-control-round"
                                    @focus="ClearErrors" placeholder="Введите логин...." v-model="login" required>
                                <div style="color: #DC3545;" v-show="login_error">
                                    {{ error_text }}
                                </div>
                            </div>
                            <div class="col-12 mb-2">
                                <label class="form-label fw-600" for="password">Пароль</label>
                                <div class="password-container">
                                    <input :type="passwordVisible ? 'text' : 'password'" id="password"
                                        class="form-control form-control-round" placeholder="Введите пароль...."
                                        v-model="password" required>
                                    <div  class="password-container-eye" v-if="password != null && password.length > 0">
                                        <img v-if="!passwordVisible" src="@/assets/images/eye/eye_open.png" id="eyeOpen"
                                            title="Показать текст пароля" @click="EyeClick()"
                                             />
                                        <img v-else src="@/assets/images/eye/eye_hide.png" id="eyeHide"
                                            @click="EyeClick()" title="Скрыть текст пароля"
                                             />
                                    </div>
                                </div>
                                <!-- <label class="form-label fw-600 mb-0 mt-2 text-end"><a href="#">Forget password ?</a></label>-->
                            </div>
                            <div class="col-12 my-2">
                                <button class="btn btn-primary btn-round btn-sm w-100" :disabled="loading">
                                    <span v-if="!loading">Войти</span>
                                    <span v-else>
                                        <span class="spinner-border spinner-border-sm mr-1" role="status"
                                            aria-hidden="true"></span>
                                        <span>&nbsp; Загрузка...</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <!--
                <div class="bottom">
                    <h4 class="title">Other Option</h4>
                    <div class="row justify-content-center">
                        <div class="col-auto"><a href="#" class="btn btn-icon btn-round btn-google-plus"><i class="fa fa-google"></i></a></div>
                        <div class="col-auto"><a href="#" class="btn btn-icon btn-round btn-facebook"><i class="fa fa-facebook"></i></a></div>
                        <div class="col-auto"><a href="#" class="btn btn-icon btn-round btn-twitter"><i class="fa fa-twitter"></i></a></div>
                    </div>
                </div>
-->
                </div>
            </div><!-- Login Form End -->
            <!--
        <div class="col-lg-6 col-12 d-none d-lg-block">
            <div class="authentication-bg-image" style="background-image: url(assets/images/authentication/login.jpg);"></div>
        </div>
        -->
        </div>
    </div>
</template>
<script>
import indDB from '@/helpers/IndexedDB'
import { textC_alignM, textL_alignM } from '@/helpers/const.js'
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import * as exp from '@/helpers/actions.js';
Object.entries(exp).forEach(([name, exported]) => { window[name] = exported; });
export default {
    computed: {
        ...mapGetters([
            'HttpStatus401',
            'HttpStatus401Message'
        ]),


        // Or return basket.getters.fruitsCount
        // (depends on your design decisions).

    },
    watch: {
        HttpStatus401(new_val, old_val) {
            if (new_val != null && new_val == true) { this.dismissCountDown = this.dismissSecs }
        }
    },
    data() {
        return {
            showEye: true,
            passwordVisible: false,
            dismissSecs: 5,
            dismissCountDown: 0,
            login: "",
            password: "",
            login_error: false,
            loading: false,
            error_text: 'Неверный логин/пароль',
            default_error: 'Неверный логин/пароль',
        }
    },
    beforeDestroy() {
        this.loading = false;
    },

    mounted() {
        if (this.HttpStatus401) { this.dismissCountDown = this.dismissSecs }
    },
    methods:

    {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        EyeClick() {
            this.passwordVisible = !this.passwordVisible;
        },
        ClearErrors() {
            this.login_error = false;
        },
        async Account() {
            this.loading = true;
            await this.$store.dispatch(AUTH_LOGIN, { Login: this.login, Password: this.password } /*formData/*{username: this.login, password: this.password}*/)
                .then(async (response) => {

                    await indDB.addToUserTokens(response.data.jwt, response.data.userName, response.data.tokenID, response.data.role, response.data.name).then(() => {
                        if (response.data.role === "user")
                            this.$router.push({ name: 'Cards'})
                        else this.$router.push({ name: 'AdminClients'})
                    });
                })
                .catch(err => {
                    if (typeof err?.response?.data == 'string') { this.error_text = err?.response?.data } else this.error_text = this.default_error
                    if (err?.response?.status == 400 || err?.response?.status == 404) {
                        this.error_text = this.default_error;
                    }
                    else {
                        this.error_text = "Ошибка сервера";
                    }
                    this.$nextTick(() => {
                        this.login_error = true;
                        this.loading = false;
                    });

                })
        }
    }
}
</script>

<style scoped>
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
    display: none;
}

.password-container {
    position: relative;
}
.password-container-eye {
    height: 20px;
    position: absolute;
    width: 20px;
    left: calc(100% - 30px);
    top: calc(50% - 10px);
}

.password-container-eye>img {
    position: relative;

}


.login-container-icon {
    position: absolute;
    height: 20px;
    top: 27%;
    left: 85%;
    width: 20px;
    cursor: pointer;
}

.login-form {
    padding: 20px 30px;
    background: white;
    border: 1px solid #a7a6a6;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    max-width: 400px !important;
}
</style>
